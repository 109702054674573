html {
  height: 100%;
}

body {
  margin: 0;
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #101827 50%, #9ca3af 50%);
  /* background-image: linear-gradient(-60deg, #5C5FFF 50%, #C95EEF 50%); */
  /* background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%); */
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: absolute;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

.outline-letter {
  stroke: white;
  stroke-width: 3px;
  fill: none;
}